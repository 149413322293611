import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useNotifications } from 'providers/notifications/useNotifications';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  useGetAccountUsersQuery,
  useInviteUserMutation,
  useResendInviteMutation,
  useRevokeInviteMutation,
  useUpdateAccountUserAccessMutation,
} from 'services/accounts';
import { useGetProfileQuery } from 'services/profile';

import { selectAccountAccess } from 'state/selectors/accounts/accountSelector';
import { selectUserLevel } from 'state/slices/userSlice';

import { AccountAccessLevel, AccountInviteStatus } from 'types/account';
import { APIError, ValidationErrorMeta } from 'types/error';
import { UserAccessLevel, UserRoleType } from 'types/user';

import { getValidationDetails } from 'utils/error';

import useGetUuid from '../utils';
import { KEYS, LABELS } from './keys';
import { UserTableRow } from './types';
import { UsersSection } from './users';

const getErrorDetails = (errorBase: FetchBaseQueryError | SerializedError | undefined, defaultErrorField = KEYS.EMAIL_FIELD) => {
  if (!errorBase) {
    return undefined;
  }

  const errorMeta = errorBase ? ((errorBase as FetchBaseQueryError).data as { meta: ValidationErrorMeta }).meta : undefined;

  if (errorMeta && Object.values(errorMeta).length) {
    return getValidationDetails(errorMeta);
  }

  return { [defaultErrorField]: ((errorBase as FetchBaseQueryError).data as APIError).errorMessage };
};

export const UsersSectionContainer = () => {
  const accountAccessLevel = useSelector(selectAccountAccess);
  const userLevel = useSelector(selectUserLevel);
  const { pushDialog } = useNotifications();
  const { t } = useTranslation();
  const { data: currentUser } = useGetProfileQuery();
  const uuid = useGetUuid();
  const { data = [], isLoading } = useGetAccountUsersQuery(uuid!, {
    skip: !uuid,
  });
  const [inviteUser, { isLoading: isSubmittingInvite, error: inviteErrorBase, reset: inviteReset }] = useInviteUserMutation();
  const [editUser, { isLoading: isSubmittingEdit, error: editErrorBase, reset: editReset }] = useUpdateAccountUserAccessMutation();
  const [revokeInvite, { isLoading: isSubmittingRevoke, reset: revokeReset }] = useRevokeInviteMutation();
  const [resendInvite, { isLoading: isSubmittingResend }] = useResendInviteMutation();

  const handleInviteUser = async (email: string, role: AccountAccessLevel) => {
    const response = await inviteUser({ accountUuid: uuid!, level: role, email });
    // @ts-ignore
    if (response?.data?.data?.success) {
      return true;
    }

    return false;
  };

  const handleEditUser = async (userUuid: string, role: AccountAccessLevel) => {
    const response = await editUser({ accountUuid: uuid!, level: role, userUuid });
    // @ts-ignore
    if (response?.data?.data?.success) {
      return true;
    }

    return false;
  };

  const handleResendInvite = async (userUuid: string) => {
    const response = await resendInvite({ accountUuid: uuid!, userUuid });
    // @ts-ignore
    if (response?.data?.data?.success) {
      return true;
    }

    const { error } = response as { error: SerializedError | FetchBaseQueryError };
    if ('data' in error) {
      const { errorMessage } = error.data as APIError;
      pushDialog({
        dialogTitle: t(LABELS.RESEND_ERROR_TITLE),
        message: errorMessage,
      });
    }

    return false;
  };

  const handleRevokeInvite = async (userUuid: string) => {
    const response = await revokeInvite({ accountUuid: uuid!, userUuid, isRevokingSelf: userUuid === currentUser?.uuid });
    // @ts-ignore
    if (response?.data?.data?.success) {
      return true;
    }

    const { error } = response as { error: SerializedError | FetchBaseQueryError };
    if ('data' in error) {
      const { errorMessage } = error.data as APIError;
      pushDialog({
        dialogTitle: t(LABELS.REVOKE_ERROR_TITLE),
        message: errorMessage,
      });
    }

    return false;
  };

  const users: UserTableRow[] = data.map((user) => ({
    ...user.user,
    id: user.user.uuid,
    level: user.access.level,
    invitedStatus: user.access.inviteStatus,
  }));

  const isAdmin = userLevel && userLevel >= UserAccessLevel.Admin;
  const isOwner = accountAccessLevel === AccountAccessLevel.Owner;
  const isPartnerAdmin = [AccountAccessLevel.Owner, AccountAccessLevel.Administrator].includes(accountAccessLevel!);
  const canInviteUser = Boolean(isAdmin || isPartnerAdmin);

  const canEditUser = (user: UserTableRow) => {
    if (!userLevel) {
      return false;
    }

    const canEditEveryoneExceptSuperadmin = userLevel >= UserAccessLevel.Admin && !user.roles.includes(UserRoleType.SuperAdmin);

    if (canEditEveryoneExceptSuperadmin) {
      return true;
    }

    const canEditOwnAccountRolesExceptOwner =
      accountAccessLevel &&
      accountAccessLevel !== AccountAccessLevel.Viewer &&
      [AccountAccessLevel.Administrator, AccountAccessLevel.Viewer].includes(user.level);

    if (canEditOwnAccountRolesExceptOwner) {
      return true;
    }

    return false;
  };

  const canResendInvite = (user: UserTableRow) => {
    const canNotResend = !userLevel || user.invitedStatus !== AccountInviteStatus.Pending;

    if (canNotResend) {
      return false;
    }

    const canEditEveryoneExceptSuperadmin = userLevel >= UserAccessLevel.Admin;

    if (canEditEveryoneExceptSuperadmin) {
      return true;
    }

    const canEditOwnAccountRolesExceptOwner = accountAccessLevel && accountAccessLevel !== AccountAccessLevel.Viewer;

    if (canEditOwnAccountRolesExceptOwner) {
      return true;
    }

    return false;
  };

  const editError = getErrorDetails(editErrorBase);
  const inviteError = getErrorDetails(inviteErrorBase);

  const editAvailableRoles =
    isAdmin || isOwner
      ? [AccountAccessLevel.Owner, AccountAccessLevel.Administrator, AccountAccessLevel.Viewer]
      : [AccountAccessLevel.Administrator, AccountAccessLevel.Viewer];

  return (
    <UsersSection
      users={users}
      isLoading={isLoading}
      inviteUser={{
        isAvailable: canInviteUser,
        onInvite: handleInviteUser,
        isSubmitting: isSubmittingInvite,
        error: inviteError,
        reset: inviteReset,
      }}
      editUser={{
        isAvailable: canEditUser,
        onEdit: handleEditUser,
        isSubmitting: isSubmittingEdit,
        error: editError,
        reset: editReset,
        availableRoles: editAvailableRoles,
      }}
      resendInvite={{
        isAvailable: canResendInvite,
        onResend: handleResendInvite,
        isSubmitting: isSubmittingResend,
      }}
      revokeInvite={{
        isAvailable: canEditUser,
        onRevoke: handleRevokeInvite,
        isSubmitting: isSubmittingRevoke,
        reset: revokeReset,
      }}
    />
  );
};
