import { OptionallyVisible } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/buttons';

import { LABELS } from '../keys';
import { TablePlaceholderContainer, TablePlaceholderText } from './styles';

interface DomainTablePlaceholderProps {
  addDomain: () => void;
  isSupport: boolean;
}

export const DomainTablePLaceholder = ({ addDomain, isSupport }: DomainTablePlaceholderProps) => {
  const { t } = useTranslation();

  return (
    <TablePlaceholderContainer>
      <TablePlaceholderText>{t(LABELS.DOMAINS.PLACEHOLDER)}</TablePlaceholderText>
      <OptionallyVisible visible={!isSupport}>
        <Button secondary onClick={addDomain}>
          {t(LABELS.DOMAINS.ADD_DOMAIN)}
        </Button>
      </OptionallyVisible>
    </TablePlaceholderContainer>
  );
};
