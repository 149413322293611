import { createApi } from '@reduxjs/toolkit/query/react';
import { Endpoint } from 'services/endpoints';

import { HTTPMethod, Response } from 'types/http';

import { getDefaultPagination } from 'utils/pagination';

import { fetchBaseQueryWithAuth } from '../api';
import { TAGS } from './keys';
import { PwcCustomerInfoResponse, PwcCustomerQuery, PwcCustomersListQuery, PwcCustomersListResponse } from './types';

const REDUCER_PATH = 'pwcCustomersApi';

export const pwcCustomersApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQueryWithAuth,
  tagTypes: [TAGS.PWC_CUSTOMERS],
  endpoints: (builder) => ({
    getPwcCustomersList: builder.query<PwcCustomersListResponse, PwcCustomersListQuery>({
      query: (requestBody) => ({
        url: Endpoint.PwcCustomers.List,
        method: HTTPMethod.POST,
        body: requestBody,
      }),
      providesTags: [TAGS.PWC_CUSTOMERS],
      transformResponse: (response: Response<PwcCustomersListResponse>) => {
        if (!response.data) {
          return { data: [], pagination: getDefaultPagination() };
        }

        return response.data;
      },
    }),
    getPwcCustomer: builder.query<PwcCustomerInfoResponse, PwcCustomerQuery>({
      query: ({ customerUuid, ...rest }) => ({
        url: Endpoint.PwcCustomers.CustomerById(customerUuid),
        method: HTTPMethod.POST,
        body: rest,
      }),
    }),
  }),
});

export const { getPwcCustomersList } = pwcCustomersApi.endpoints;
export const { useGetPwcCustomersListQuery, useGetPwcCustomerQuery } = pwcCustomersApi;
export default pwcCustomersApi;
