import styled from '@emotion/styled/macro';

import { COLUMN_IDS } from './keys';

export const Container = styled.div((_) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  padding: '5px 0',
  [`& [data-column="${COLUMN_IDS.CREATED_AT}"]`]: {
    minWidth: 90,
    maxWidth: 100,
  },
  [`& [data-column="${COLUMN_IDS.EMAIL}"]`]: {
    minWidth: 120,
    maxWidth: 200,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export const ActionBar = styled.div((_) => ({
  display: 'flex',
  gap: 8,
}));
