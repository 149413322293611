import { t } from 'i18next';

import { DataTableColumn } from 'components/dataTable';

import RoutePath from 'router/path';

import { makeLabels } from 'utils/i18n';

export const KEYS = {
  TABLE_DATE_FORMAT: 'MMM DD, YYYY HH:mm A',
  ROUTE_MATCH: `${RoutePath.Pwc.Accounts.ById}/:route`,
};

export const MESSAGES_NAMESPACE = 'pages.account.edit.sections.settlement';
export const LABELS = makeLabels(
  {
    SETTLEMENT: {
      PANEL_TITLE: ['settlement.panelTitle', 'Settlement'],
      ADD_METHOD: ['settlement.addMethod', 'Add Bank Account'],
      PLACEHOLDER: ['settlement.placeholder', 'No Bank Accounts Registered'],
      TABLE_COLUMNS: {
        BANK_NAME: ['settlement.tableColumns.name', 'Name'],
        CURRENCY: ['settlement.tableColumns.currency', 'Currency'],
        BRIDGE_ACCOUNT_ID: ['settlement.tableColumns.bridgeAccountId', 'Bridge Account ID'],
        CREATED_AT: ['settlement.tableColumns.createdAt', 'Created At'],
      },
    },
  },
  MESSAGES_NAMESPACE,
);

export const SETTLEMENT_COLUMN_IDS = {
  BANK_NAME: 'bankName',
  CURRENCY: 'currency',
  BRIDGE_ACCOUNT_ID: 'bridgeExternalAccountId',
  CREATED_AT: 'createdAt',
};

export const SETTLEMENT_COLUMNS = (isPartner: boolean): DataTableColumn[] => [
  { id: SETTLEMENT_COLUMN_IDS.BANK_NAME, label: t(LABELS.SETTLEMENT.TABLE_COLUMNS.BANK_NAME) },
  { id: SETTLEMENT_COLUMN_IDS.CURRENCY, label: t(LABELS.SETTLEMENT.TABLE_COLUMNS.CURRENCY) },
  { id: SETTLEMENT_COLUMN_IDS.BRIDGE_ACCOUNT_ID, label: t(LABELS.SETTLEMENT.TABLE_COLUMNS.BRIDGE_ACCOUNT_ID), hidden: isPartner },
  { id: SETTLEMENT_COLUMN_IDS.CREATED_AT, label: t(LABELS.SETTLEMENT.TABLE_COLUMNS.CREATED_AT) },
];
