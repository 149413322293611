import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';
import { DataTableRow } from 'components/dataTable';
import DataTable from 'components/dataTable/dataTable';
import { ExportButton } from 'components/filters/export/exportButton';
import { TableFilters } from 'components/filters/table/tableFilters';
import { TableFiltersProps } from 'components/filters/table/types';
import { SearchInput } from 'components/inputs/search/search';

import { TablePagination } from 'types/pagination';
import { TableSorting } from 'types/sort';

import { OrderTableCell } from './components/orderTableCell';
import { COLUMNS, LABELS } from './keys';
import { ActionBar, Container } from './styles';
import { Filters, OrderDataRow } from './types';
import { makeFilterSections } from './utils';

interface OrderListPageProps {
  orders: OrderDataRow[];
  isLoading: boolean;
  pagination: TablePagination;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (itemsPerPage: number) => void;
  sorting: TableSorting;
  onSort: (event: MouseEvent, field: string) => void;
  onSearch: (search: string) => void;
  search: string;
  onRowClick: (row: DataTableRow) => void;
  exportProps: {
    onExport: () => void;
    isLoading: boolean;
  };
  filterProps: TableFiltersProps<Filters>;
  isS4cUser: boolean;
}

export const OrderList = ({
  orders,
  isLoading,
  pagination,
  onChangePage,
  onChangeRowsPerPage,
  sorting,
  onSort,
  onSearch,
  search,
  filterProps,
  exportProps,
  onRowClick,
  isS4cUser,
}: OrderListPageProps) => {
  const { t } = useTranslation();
  const sections = makeFilterSections(filterProps.filterSettings, isS4cUser);
  const placeholder = isS4cUser ? LABELS.SEARCH_PLACEHOLDER_S4USER : LABELS.SEARCH_PLACEHOLDER_PARTNER;

  return (
    <Panel>
      <Container>
        <SearchInput search={search} onSearch={onSearch} placeholder={t(placeholder)} />
        <ActionBar>
          <TableFilters {...filterProps} sections={sections} />
          <ExportButton onExport={exportProps.onExport} isLoading={exportProps.isLoading} filterProps={filterProps} />
        </ActionBar>
        <DataTable
          columns={COLUMNS}
          rows={orders}
          loading={isLoading}
          CellComponent={OrderTableCell}
          pagination={pagination}
          onSort={onSort}
          order={sorting.order}
          orderBy={sorting.orderBy}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          onRowClick={onRowClick}
        />
      </Container>
    </Panel>
  );
};
