import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';
import { DataTable, DataTableRow } from 'components/dataTable';
import { TableFilters } from 'components/filters/table/tableFilters';
import { TableFiltersProps } from 'components/filters/table/types';
import { SearchInput } from 'components/inputs/search/search';

import { TablePagination } from 'types/pagination';
import { TableSorting } from 'types/sort';

import { CustomerTableCell } from './components/customerTableCell';
import LABELS, { COLUMNS } from './keys';
import { ActionBar, Container } from './styles';
import { Filters, PwcCustomerDataRow } from './types';
import { makeFilterSections } from './utils';

interface PwcCustomersListProps {
  customers: PwcCustomerDataRow[];
  isLoading: boolean;
  pagination: TablePagination;
  onRowClick: (row: DataTableRow) => void;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (itemsPerPage: number) => void;
  onSort: (event: MouseEvent, property: string) => void;
  sorting: TableSorting;
  onSearch: (value: string) => void;
  search: string;
  filterProps: TableFiltersProps<Filters>;
}

const PwcCustomersList = ({
  customers,
  isLoading,
  pagination,
  onRowClick,
  onChangePage,
  onChangeRowsPerPage,
  onSort,
  sorting,
  onSearch,
  search,
  filterProps,
}: PwcCustomersListProps) => {
  const { t } = useTranslation();

  const sections = makeFilterSections(filterProps.filterSettings);

  return (
    <Panel>
      <Container>
        <SearchInput search={search} onSearch={onSearch} placeholder={t(LABELS.SEARCH_PLACEHOLDER)} />
        <ActionBar>
          <TableFilters {...filterProps} sections={sections} />
        </ActionBar>
        <DataTable
          columns={COLUMNS}
          rows={customers}
          loading={isLoading}
          CellComponent={CustomerTableCell}
          pagination={pagination}
          onRowClick={onRowClick}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          onSort={onSort}
          order={sorting.order}
          orderBy={sorting.orderBy}
        />
      </Container>
    </Panel>
  );
};

export default PwcCustomersList;
