import { t } from 'i18next';

import { DataTableColumn } from 'components/dataTable';

import { SortOrder } from 'types/sort';

export const COLUMN_IDS = {
  EMAIL: 'email',
  CUSTOMER_ID: 'uuid',
  CREATED_AT: 'createdAt',
  LAST_PURCHASED_AT: 'lastPurchasedAt',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  ACTIONS: 'actions',
};

export const KEYS = {
  FILTERS_KEY: 'pwcCustomers',
  SEARCH_DEBOUNCE_TIMEOUT: 1000,
  TABLE_DATE_FORMAT_CURRENT_YEAR: 'MMM DD',
  TABLE_DATE_FORMAT: 'MMM DD, YYYY',
  TABLE_DATE_AND_TIME_FORMAT: 'MMM DD, YYYY, HH:mm A',
  DEFAULT_TABLE_SORTING: {
    FIELD: COLUMN_IDS.EMAIL,
    DIRECTION: SortOrder.desc,
  },
};

const TABLE_COLUMNS_LABELS: Record<(typeof COLUMN_IDS)[keyof typeof COLUMN_IDS], [string, string]> = {
  [COLUMN_IDS.EMAIL]: ['page.pwcCustomers.list.table.columns.email', 'Email'],
  [COLUMN_IDS.CUSTOMER_ID]: ['page.pwcCustomers.list.table.columns.customerId', 'Customer ID'],
  [COLUMN_IDS.CREATED_AT]: ['page.pwcCustomers.list.table.columns.createdAt', 'Creation Date'],
  [COLUMN_IDS.LAST_PURCHASED_AT]: ['page.pwcCustomers.list.table.columns.createdAt', 'Last Purchased At'],
  [COLUMN_IDS.ACTIONS]: ['page.pwcCustomers.list.table.columns.actions', ''],
};

const LABELS = {
  BREADCRUMBS: [['page.pwcCustomers.breadcrumbs', 'Customers']],
  PAGE_TITLE: ['page.pwcCustomers.list.title', 'Customer List'],
  SEARCH_PLACEHOLDER: ['page.pwcCustomers.list.search.placeholder', 'Search by User Email, Customer ID'],
  FILTERS: {
    DATE: {
      LABEL: ['page.pwcCustomers.list.filter.date.label', 'Creation Date'],
    },
    LAST_PURCHASED_AT_DATE: {
      LABEL: ['page.pwcCustomers.list.filter.purchasedDate.label', 'Last Purchased Date'],
    },
  },
  TABLE_COLUMNS: TABLE_COLUMNS_LABELS,
  ACTIONS: {
    TITLE: ['page.pwcCustomers.list.actions.title', 'Actions'],
    OPEN_CUSTOMER_DETAILS: ['page.pwcCustomers.list.actions.details', 'Open Customer Details'],
    COPY_CUSTOMER_ID: ['page.pwcCustomers.list.actions.copyCustomerId', 'Copy Customer ID'],
    COPY_CUSTOMER_EMAIL: ['page.pwcCustomers.list.actions.copyCustomerEmail', "Copy Customer's E-mail"],
  },
};

export const COLUMNS: DataTableColumn[] = [
  { id: COLUMN_IDS.EMAIL, label: t(LABELS.TABLE_COLUMNS[COLUMN_IDS.EMAIL]), sortable: true },
  { id: COLUMN_IDS.CUSTOMER_ID, label: t(LABELS.TABLE_COLUMNS[COLUMN_IDS.CUSTOMER_ID]) },
  { id: COLUMN_IDS.CREATED_AT, label: t(LABELS.TABLE_COLUMNS[COLUMN_IDS.CREATED_AT]), sortable: true },
  { id: COLUMN_IDS.LAST_PURCHASED_AT, label: t(LABELS.TABLE_COLUMNS[COLUMN_IDS.LAST_PURCHASED_AT]), sortable: true },
  { id: COLUMN_IDS.ACTIONS, label: t(LABELS.TABLE_COLUMNS[COLUMN_IDS.ACTIONS]) },
];

export default LABELS;
