import { t } from 'i18next';

import { getDefaultDatePresets } from 'components/filters/date/keys';
import { DateFilterPassedProps } from 'components/filters/types';

import { Filter, FilterSection, FilterType } from 'types/filters';

import LABELS from './keys';
import { Filters } from './types';

export const makeFilterSections = (filterMap: Record<string, Filter<unknown>>): FilterSection[] => {
  return [
    {
      key: 'filters',
      content: [filterMap.date, filterMap.lastPurchasedAt],
    },
  ];
};

export const makeFilters = (): Record<string, Filter<unknown>> => {
  const dateFilter: Filter<DateFilterPassedProps> = {
    key: 'date',
    label: t(LABELS.FILTERS.DATE.LABEL),
    type: FilterType.Date,
    presets: getDefaultDatePresets(),
  };

  const purchasedAtFilter: Filter<DateFilterPassedProps> = {
    key: 'lastPurchasedAt',
    label: t(LABELS.FILTERS.LAST_PURCHASED_AT_DATE.LABEL),
    type: FilterType.Date,
    presets: getDefaultDatePresets(),
  };

  return [dateFilter, purchasedAtFilter].reduce((acc, cur) => {
    return { ...acc, [cur.key]: cur };
  }, {});
};

export const prepareFilters = (filters: Filters): Filters | undefined => {
  if (!filters) {
    return undefined;
  }

  const { date, lastPurchasedAt } = filters;

  const nextFilters: Filters = {};
  if (date) {
    nextFilters.date = date;
  }

  if (lastPurchasedAt) {
    nextFilters.lastPurchasedAt = lastPurchasedAt;
  }

  return nextFilters;
};
