import { TableBody, TableContainer } from '@mui/material';
import React, { CSSProperties, FunctionComponent, MouseEvent, ReactNode, useCallback } from 'react';

import { Pagination } from 'types/pagination';
import { SortOrder } from 'types/sort';

import LoadingIndicator from '../loadingIndicator';
import { OptionallyVisible } from '../optionallyVisible';
import { LoaderWrapper, StyledTable, TableWrapper } from './dataTable.styles';
import { DataTableCellProps, DataTableColumn, DataTableRow } from './dataTable.types';
import { DataTableHead } from './dataTableHead';
import { DataTablePagination } from './dataTablePagination';
import { DataTableRowComponent } from './dataTableRow';

export interface DataTableProps {
  columns: DataTableColumn[];
  orderBy?: string;
  order?: SortOrder;
  onSort?: (event: MouseEvent, property: string) => void;
  loading?: boolean;
  error?: string;
  rows: DataTableRow[];
  updateRow?: (nextRow: Partial<DataTableRow>) => Promise<any>;
  onRowClick?: (row: DataTableRow) => void;
  onChangePage?: (page: number) => void;
  pagination?: Pagination;
  styles?: CSSProperties;
  placeholder?: ReactNode;
  CellComponent?: FunctionComponent<DataTableCellProps>;
  rowStyles?: CSSProperties;
  headStyles?: CSSProperties;
  hideHead?: boolean;
  onChangeRowsPerPage?: (nextItemsPerPage: number) => void;
}

export const DataTable = React.memo(
  ({
    columns,
    orderBy = '',
    order = SortOrder.asc,
    onSort = () => {},
    loading = false,
    error,
    rows: filteredTableData,
    updateRow,
    onRowClick,
    onChangePage,
    pagination,
    styles,
    placeholder,
    CellComponent,
    rowStyles,
    headStyles,
    hideHead = false,
    onChangeRowsPerPage,
  }: DataTableProps) => {
    const getRowClickHandler = useCallback(
      (row: DataTableRow) =>
        onRowClick
          ? () => {
              const item = filteredTableData.find((item) => item.id === row.id);
              if (!item) {
                return;
              }

              onRowClick(item);
            }
          : undefined,
      [onRowClick, filteredTableData],
    );

    const hasNoResults = !filteredTableData.length;
    const placeholderProvided = Boolean(placeholder);
    const placeholderVisible = !loading && hasNoResults && placeholderProvided;

    const paginationVisible = Boolean(pagination);

    if (!filteredTableData) {
      return null;
    }

    if (loading) {
      return (
        <TableWrapper style={styles}>
          <LoadingIndicator padded />
          <OptionallyVisible visible={paginationVisible}>
            <DataTablePagination pagination={pagination} onChangeRowsPerPage={onChangeRowsPerPage} onChangePage={onChangePage} />
          </OptionallyVisible>
        </TableWrapper>
      );
    }

    if (error) {
      return (
        <TableWrapper style={styles}>
          <LoaderWrapper>{error}</LoaderWrapper>
        </TableWrapper>
      );
    }

    return (
      <TableWrapper>
        <TableContainer>
          <StyledTable aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
            <OptionallyVisible visible={!hideHead}>
              <DataTableHead
                columns={columns.filter((column) => !column.hidden)}
                order={order}
                orderBy={orderBy}
                onRequestSort={onSort}
                styles={headStyles}
              />
            </OptionallyVisible>
            <OptionallyVisible visible={!placeholderVisible}>
              <TableBody>
                {filteredTableData.map((row) => (
                  <DataTableRowComponent
                    key={row.id}
                    columns={columns}
                    row={row}
                    updateRow={updateRow}
                    CellComponent={CellComponent}
                    onClick={getRowClickHandler(row)}
                    styles={rowStyles}
                  />
                ))}
              </TableBody>
            </OptionallyVisible>
          </StyledTable>
          <OptionallyVisible visible={placeholderVisible}>{placeholder}</OptionallyVisible>
        </TableContainer>
        <OptionallyVisible visible={paginationVisible}>
          <DataTablePagination pagination={pagination} onChangeRowsPerPage={onChangeRowsPerPage} onChangePage={onChangePage} />
        </OptionallyVisible>
      </TableWrapper>
    );
  },
);

export default DataTable;
