import { t } from 'i18next';

import { BadgeType } from 'components/elements/badge/types';

import { CustomerKYCStatus } from 'types/customer';

export const LABELS: Record<CustomerKYCStatus, string[]> = {
  [CustomerKYCStatus.Approved]: ['page.customers.list.table.kycStatus.approved', 'Approved'],
  [CustomerKYCStatus.Rejected]: ['page.customers.list.table.kycStatus.rejected', 'Rejected'],
  [CustomerKYCStatus.PendingApproval]: ['page.customers.list.table.kycStatus.pendingApproval', 'Pending Approval'],
  [CustomerKYCStatus.Submitted]: ['page.customers.list.table.kycStatus.submitted', 'Submitted'],
  [CustomerKYCStatus.Locked]: ['page.customers.list.table.kycStatus.locked', 'Locked'],
  [CustomerKYCStatus.Disabled]: ['page.customers.list.table.kycStatus.disabled', 'Disabled'],
  [CustomerKYCStatus.Divested]: ['page.customers.list.table.kycStatus.divested', 'Divested'],
  [CustomerKYCStatus.Closed]: ['page.customers.list.table.kycStatus.closed', 'Closed'],
  [CustomerKYCStatus.Null]: ['page.customers.list.table.kycStatus.unknown', ''],
};

export const badgePropsMap: Record<CustomerKYCStatus, { type: BadgeType; label: string }> = {
  [CustomerKYCStatus.Approved]: {
    type: BadgeType.Success,
    label: t(LABELS[CustomerKYCStatus.Approved]),
  },
  [CustomerKYCStatus.Rejected]: {
    type: BadgeType.Fail,
    label: t(LABELS[CustomerKYCStatus.Rejected]),
  },
  [CustomerKYCStatus.PendingApproval]: {
    type: BadgeType.Default,
    label: t(LABELS[CustomerKYCStatus.PendingApproval]),
  },
  [CustomerKYCStatus.Submitted]: {
    type: BadgeType.Default,
    label: t(LABELS[CustomerKYCStatus.Submitted]),
  },
  [CustomerKYCStatus.Locked]: {
    type: BadgeType.Fail,
    label: t(LABELS[CustomerKYCStatus.Locked]),
  },
  [CustomerKYCStatus.Disabled]: {
    type: BadgeType.Fail,
    label: t(LABELS[CustomerKYCStatus.Disabled]),
  },
  [CustomerKYCStatus.Divested]: {
    type: BadgeType.Fail,
    label: t(LABELS[CustomerKYCStatus.Divested]),
  },
  [CustomerKYCStatus.Closed]: {
    type: BadgeType.Fail,
    label: t(LABELS[CustomerKYCStatus.Closed]),
  },
  [CustomerKYCStatus.Null]: {
    type: BadgeType.Default,
    label: t(LABELS[CustomerKYCStatus.Null]),
  },
};
