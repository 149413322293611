import React from 'react';

import OptionallyVisible from '../../optionallyVisible';
import { BarContainer, Icon, Item, Label } from './styles';

export interface BarSwitchOption {
  label: string;
  icon?: JSX.Element;
  value: string;
}

interface BarSwitchProps {
  value: string;
  onChange: (value: BarSwitchOption) => void;
  options: BarSwitchOption[];
  disabled?: boolean;
}

export const BarSwitch = ({ value, onChange, disabled, options, ...props }: BarSwitchProps) => {
  const handleChange = (option: BarSwitchOption) => () => {
    if (disabled) {
      return;
    }

    onChange(option);
  };

  return (
    <BarContainer {...props}>
      {options.map((option) => (
        <Item key={option.value} selected={value === option.value} onClick={handleChange(option)}>
          <OptionallyVisible visible={Boolean(option.icon)}>
            <Icon>{option.icon}</Icon>
          </OptionallyVisible>
          <Label>{option.label}</Label>
        </Item>
      ))}
    </BarContainer>
  );
};
