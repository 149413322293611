import React, { ComponentType, FunctionComponent } from 'react';

import { DataTableCellProps } from 'components/dataTable/dataTable.types';

import { COLUMN_IDS } from '../../../../keys';
import { ActionsCell } from './actionsCell';
import { CryptoCell } from './cryptoCell';
import { DateCell } from './dateCell';
import { DefaultCell } from './defaultCell';
import { FiatCell } from './fiatCell';
import { StatusCell } from './statusCell';

const cellComponentMap: { [key: string]: ComponentType<DataTableCellProps> } = {
  [COLUMN_IDS.STATUS]: StatusCell,
  [COLUMN_IDS.ID]: DefaultCell,
  [COLUMN_IDS.DATE]: DateCell,
  [COLUMN_IDS.FIAT_AMOUNT]: FiatCell,
  [COLUMN_IDS.CRYPTO_AMOUNT]: CryptoCell,
  [COLUMN_IDS.ACTIONS]: ActionsCell,
  default: DefaultCell,
};

export const OrderCell: FunctionComponent<DataTableCellProps> = (props) => {
  const { column } = props;
  const SelectedComponent = cellComponentMap[column.id] || cellComponentMap.default;

  return <SelectedComponent {...props} />;
};
