import React from 'react';

import { DataTableCellProps, TableCell } from 'components/dataTable';

import { OrderDataRow } from '../../types';
import { formatNumber } from '../../utils';

export const FiatCell = ({ row, column }: DataTableCellProps) => {
  const value = row[column.id];

  return (
    <TableCell data-column={column.id}>
      {formatNumber(value)} {(row as OrderDataRow).fiatCurrencyCode?.toUpperCase()}
    </TableCell>
  );
};
