import React from 'react';
import { useSelector } from 'react-redux';
import { useMatch } from 'react-router';
import { useGetCountriesListQuery } from 'services/assets';
import { useGetMerchantsBankAccountsQuery } from 'services/merchants';

import { SelectItem } from 'components/inputs/select/select';

import selectAccount from 'state/selectors/accounts/accountSelector';
import selectMerchant from 'state/selectors/merchants/merchantSelector';
import { selectUserLevel } from 'state/slices/userSlice';

import { UserAccessLevel } from 'types/user';

import { KEYS } from './keys';
import { Settlement } from './settlement';
import { transformBankAccountsListDataToTableFormat } from './utils';

export const SettlementContainer = () => {
  const match = useMatch(KEYS.ROUTE_MATCH);
  const { account } = useSelector(selectAccount);
  const userLevel = useSelector(selectUserLevel);
  const { merchant } = useSelector(selectMerchant);
  const isPartner = Boolean(userLevel && userLevel === UserAccessLevel.Partner);
  const { uuid } = match?.params ?? { uuid: account.uuid };

  const { data: countries = [] } = useGetCountriesListQuery();
  const { data, isFetching } = useGetMerchantsBankAccountsQuery(uuid!, { skip: !uuid });
  const { kyc } = merchant;

  const bankAccounts = transformBankAccountsListDataToTableFormat(data?.data);

  const mappedCountries: SelectItem[] = countries.map((country) => ({
    key: country.isoAlpha3,
    icon: country.iconUrl,
    label: country.name,
  }));

  return (
    <Settlement
      isPartner={isPartner}
      countries={mappedCountries}
      merchantId={uuid!}
      loading={isFetching}
      bankAccounts={bankAccounts}
      kyc={kyc}
    />
  );
};
