import { DateFilterValue } from 'types/filters';
import { Response } from 'types/http';
import { RequestPagination, ServerPagination } from 'types/pagination';
import { RequestSorting } from 'types/sort';

export interface MerchantsListQuery {
  filters?: {
    uuid?: string | string[];
    date?: DateFilterValue;
    status?: string;
  };
  search?: string;
  pagination?: RequestPagination;
  sort?: RequestSorting;
}

export enum MerchantStatus {
  Active = 'active',
  Inactive = 'inactive',
  Deactivated = 'deactivated',
}
export enum AccountOwnerType {
  Individual = 'individual',
  Business = 'business',
}

export enum PwcThemeMode {
  Light = 'light',
  Dark = 'dark',
  SameAsBrowser = 'sameAsBrowser',
}

export interface MerchantInfo extends MerchantCheckoutSettings {
  uuid: string;
  name: string;
  publicKey: string;
  createdAt: string;
  bridgeCustomerId: string;
  mainBankAccountUuid: string | null;
  status: MerchantStatus;
  shift4MerchantId: string | null;
  ownerEmail: string;
  ownerFullName: string;
  processingFee: number;
  webhookUrl: string | null;
  meshSubclientId: string | null;
  payout: {
    balance: string;
    nextPayout: string;
  };
  kyc: MerchantKyc;
}

export interface MerchantKyc {
  uuid?: string;
  merchantUuid?: string;
  tosLink?: string;
  kycStatus?: MerchantKycStatuses;
  tosStatus?: string;
  rejectionReasons?: any[];
  updatedAt?: Date;
}

export enum MerchantKycStatuses {
  NotStarted = 'not_started',
  Pending = 'pending',
  Incomplete = 'incomplete',
  AwaitingUbo = 'awaiting_ubo',
  ManualReview = 'manual_review',
  UnderReview = 'under_review',
  Approved = 'approved',
  Rejected = 'rejected',
}

export interface MerchantCheckoutSettings {
  title: string | null;
  lightModeLogoUrl: File | string;
  darkModeLogoUrl: File | string;
}

interface MerchantBankAddress {
  streetLine1: string;
  streetLine2?: string;
  city: string;
  state?: string;
  postalCode: string;
  country: string;
}

export interface MerchantBankInfo {
  uuid: string;
  merchantUuid: string;
  accountType: string;
  currency: string;
  bankName: string;
  createdAt: string;
  accountOwnerName: string;
  accountOwnerType: AccountOwnerType;
  address?: MerchantBankAddress;
  firstName?: string;
  lastName?: string;
  businessName?: string;
  account?: {
    accountNumber: string;
    routingNumber: string;
  };
  iban?: string;
  bridgeExternalAccountId: string;
  isMain?: boolean;
}

export type CreateBankAccountPayload = {
  uuid: string;
  bankName: string;
  accountOwnerName: string;
  accountOwnerType: AccountOwnerType;
  currency: string;
  address: MerchantBankAddress;
  account: {
    accountNumber: string;
    routingNumber: string;
  };
  iban?: {
    accountNumber: string;
    bic: string;
    country: string;
  };
  firstName?: string;
  lastName?: string;
  businessName?: string;
};

export type MerchantsListResponse = { data: MerchantInfo[]; pagination: ServerPagination };
export type MerchantsInfoResponse = { data: MerchantInfo };
export type MerchantsBanksResponse = { data: MerchantBankInfo[] };
export type CreateMerchantBankAccountResponse = Response<{ data: MerchantBankInfo }>;
