import dayjs from 'dayjs';
import { PwcOrderShort } from 'services/pwcCustomers/types';

import i18n from 'locales/i18next';

import { convertValueToDayjs } from 'utils/date';
import { formatNumberI18n } from 'utils/i18n';

import { KEYS } from '../../keys';
import { OrderDataRow } from './types';

export const transformOrdersDataToTableFormat = (orders?: PwcOrderShort[]): OrderDataRow[] => {
  return orders
    ? orders.map((order) => ({
        ...order,
        id: order.uuid,
      }))
    : [];
};
export const formatNumber = (stringOrNumber?: string | number) => formatNumberI18n(i18n.language)(Number(stringOrNumber ?? 0));

export const formatDate = (date: string) => {
  const now = dayjs();
  const dateValue = convertValueToDayjs(date);
  const isSameYear = now.year() === dateValue?.year();
  const format = isSameYear ? KEYS.DATE_FORMAT : KEYS.DATE_AND_YEAR_FORMAT;

  return convertValueToDayjs(date)?.format(format);
};
