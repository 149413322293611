import { PageWrapper } from 'components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetPwcCustomersListQuery } from 'services/pwcCustomers';
import { PwcCustomersListQuery } from 'services/pwcCustomers/types';

import { DataTableRow } from 'components/dataTable';

import RoutePath from 'router/path';

import { usePersistentFilters } from 'utils/filters';
import { decoratePagination, getDefaultPagination, undecoratePagination } from 'utils/pagination';
import { usePagination } from 'utils/tables/pagination';
import { usePersistentSearch } from 'utils/tables/search';
import { getRequestSorting, useTableSorting } from 'utils/tables/sorting';
import { useDebouncedQuery } from 'utils/tables/useDebouncedQuery';

import PwcCustomersList from '.';
import LABELS, { KEYS } from './keys';
import { Filters, PwcCustomerDataRow } from './types';
import { makeFilters, prepareFilters } from './utils';

const PwcCustomersContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const breadcrumbs = LABELS.BREADCRUMBS.map((item) => t(item));
  const { search, setSearch } = usePersistentSearch(KEYS.SEARCH_DEBOUNCE_TIMEOUT, KEYS.FILTERS_KEY);
  const { filters, updateFilters, clearFilters } = usePersistentFilters<Filters>({}, KEYS.FILTERS_KEY);
  const { sorting, onSort } = useTableSorting({
    defaultSorting: {
      direction: KEYS.DEFAULT_TABLE_SORTING.DIRECTION,
      field: KEYS.DEFAULT_TABLE_SORTING.FIELD,
    },
  });
  const { pagination, setPagination, changeRowsPerPage, changePage } = usePagination(getDefaultPagination(), [filters, sorting, search]);
  const customerListQuery: PwcCustomersListQuery = useDebouncedQuery(
    () => ({
      pagination: undecoratePagination(pagination),
      sort: getRequestSorting(sorting),
      filters: prepareFilters(filters),
      search: search || undefined,
    }),
    [pagination, filters, sorting, search],
  );
  const { data, isFetching } = useGetPwcCustomersListQuery(customerListQuery);

  const navigateToCustomer = ({ uuid }: DataTableRow) => navigate(RoutePath.pwc.customerById(uuid));
  const filterSettings = makeFilters();
  const customers: PwcCustomerDataRow[] = (data?.data || []).map((customer) => {
    return {
      ...customer,
      id: customer.uuid,
    };
  });

  useEffect(() => {
    if (data?.pagination) {
      setPagination(decoratePagination(data?.pagination));
    }
  }, [data?.pagination]);

  return (
    <PageWrapper pageTitle={t(LABELS.PAGE_TITLE)} breadcrumbs={breadcrumbs}>
      <PwcCustomersList
        pagination={pagination}
        customers={customers}
        isLoading={isFetching}
        onChangePage={changePage}
        onChangeRowsPerPage={changeRowsPerPage}
        onSort={onSort}
        sorting={sorting}
        onSearch={setSearch}
        onRowClick={navigateToCustomer}
        search={search}
        filterProps={{
          filterSettings,
          onFilterChange: updateFilters,
          onFiltersClear: clearFilters,
          filters,
        }}
      />
    </PageWrapper>
  );
};

export default PwcCustomersContainer;
