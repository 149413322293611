import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { DataTableCellProps, TableCell } from 'components/dataTable';
import { CopyMenuItem, MenuItem } from 'components/dataTable/cells';
import { ActionMenu } from 'components/dataTable/cells/actionMenu';

import RoutePath from 'router/path';

import { LABELS } from '../../../../keys';
import { OrderDataRow } from '../../types';

export const ActionsCell = ({ row }: DataTableCellProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { uuid } = row as OrderDataRow;

  const navigateToOrder = (uuid: string) => () => navigate(RoutePath.pwc.orderById(uuid));

  return (
    <TableCell>
      <ActionMenu title={t(LABELS.ACTIONS.ORDER.TITLE)}>
        <CopyMenuItem value={uuid}>{t(LABELS.ACTIONS.ORDER.COPY_ORDER_ID)}</CopyMenuItem>
        <MenuItem onClick={navigateToOrder(uuid)}>{t(LABELS.ACTIONS.ORDER.OPEN_DETAILS)}</MenuItem>
      </ActionMenu>
    </TableCell>
  );
};
