import styled from '@emotion/styled';
import { TableRow as MaterialTableRow } from '@mui/material';
import { CSSProperties, MouseEvent } from 'react';

export interface StyledTableRowProps {
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  extraStyles?: CSSProperties;
}

export const StyledTableRow = styled(MaterialTableRow, {
  shouldForwardProp: (prop) => prop !== 'extraStyles',
})<StyledTableRowProps>((props) => ({
  fontFamily: props.theme.fonts.primary,
  cursor: props.onClick ? 'pointer' : 'unset',
  '& > td:first-of-type': {
    paddingLeft: 10,
  },
  '& > td:last-child': {
    paddingRight: 30,
    textAlign: 'right',
  },
  '&:hover': {
    background: 'none !important',
  },
  '&:last-child th, &:last-child td': {
    borderBottom: 0,
  },
  ...props.extraStyles,
}));
