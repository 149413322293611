import React, { FunctionComponent } from 'react';

import { TableCell } from 'components/dataTable';
import { DataTableCellProps } from 'components/dataTable/dataTable.types';

import { convertValueToDayjs } from 'utils/date';

import { KEYS } from '../../keys';

export const DateCell: FunctionComponent<DataTableCellProps> = ({ row, column }) => {
  const getCellValue = () => {
    const columnName = column.id;
    const cellValue = row[columnName];

    return convertValueToDayjs(cellValue)?.format(KEYS.TABLE_DATE_FORMAT);
  };

  return <TableCell data-column={column.id}>{getCellValue()}</TableCell>;
};
