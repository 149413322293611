import { OptionallyVisible } from 'components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';
import DataTable from 'components/dataTable/dataTable';
import IconAdd from 'components/icons/add';

import { AddDomainModal } from './components/addDomainModal';
import { makeDomainTableCell } from './components/domainTableCell';
import { DomainTablePLaceholder } from './components/tablePlaceholder';
import { DOMAINS_COLUMNS, LABELS } from './keys';
import { ActionPanel, AddRecordButton } from './styles';
import { DomainTableRow } from './types';

interface PaymentMethodsSectionProps {
  domains: DomainTableRow[];
  addDomain: (domain: string) => Promise<boolean>;
  removeDomain: (domain: string) => Promise<boolean>;
  downloadFile: () => void;
  reset: () => void;
  error: Record<string, string>;
  isSubmitting: boolean;
  isRemoving: boolean;
  isSupport: boolean;
}

export const ApplePayDomainsSection = ({
  isSupport,
  domains,
  addDomain,
  removeDomain,
  downloadFile,
  isSubmitting,
  isRemoving,
  reset,
  error,
}: PaymentMethodsSectionProps) => {
  const { t } = useTranslation();
  const [isAddModalVisible, setAddModalVisible] = useState<boolean>(false);

  const openModal = () => {
    setAddModalVisible(true);
  };

  const closeModal = () => {
    setAddModalVisible(false);
  };

  const TableCell = makeDomainTableCell({ removeDomain, isRemoving });

  return (
    <Panel label={t(LABELS.DOMAINS.PANEL_TITLE)}>
      <OptionallyVisible visible={!isSupport}>
        <ActionPanel>
          <AddRecordButton flat onClick={openModal}>
            <IconAdd />
            {t(LABELS.DOMAINS.ADD_DOMAIN)}
          </AddRecordButton>
        </ActionPanel>
      </OptionallyVisible>
      <AddDomainModal
        onSubmit={addDomain}
        isSubmitting={isSubmitting}
        reset={reset}
        open={isAddModalVisible}
        onClose={closeModal}
        error={error?.add}
        downloadFile={downloadFile}
      />
      <DataTable
        columns={DOMAINS_COLUMNS}
        rows={domains}
        placeholder={<DomainTablePLaceholder isSupport={isSupport} addDomain={openModal} />}
        CellComponent={TableCell}
      />
    </Panel>
  );
};
