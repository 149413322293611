import { PageWrapper } from 'components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetPwcCustomerQuery } from 'services/pwcCustomers';

import LoadingIndicator from 'components/loadingIndicator';

import { decoratePagination, getDefaultPagination, undecoratePagination } from 'utils/pagination';
import { usePagination } from 'utils/tables/pagination';
import { getRequestSorting, useTableSorting } from 'utils/tables/sorting';

import { CustomerDetails } from './customerDetails';
import { KEYS, LABELS } from './keys';

export const PwcCustomerViewContainer = () => {
  const { t } = useTranslation();

  const params = useParams();
  const { uuid } = params;

  const { sorting, onSort } = useTableSorting({
    defaultSorting: {
      direction: KEYS.DEFAULT_TABLE_SORTING.DIRECTION,
      field: KEYS.DEFAULT_TABLE_SORTING.FIELD,
    },
  });

  const { pagination, setPagination, changeRowsPerPage, changePage } = usePagination(getDefaultPagination(), [sorting]);
  const { data: customerPwc, isLoading } = useGetPwcCustomerQuery(
    { customerUuid: uuid!, sort: getRequestSorting(sorting), pagination: undecoratePagination(pagination) },
    {
      skip: !uuid,
    },
  );

  useEffect(() => {
    if (customerPwc?.data.orders.pagination) {
      setPagination(decoratePagination(customerPwc?.data.orders.pagination));
    }
  }, [customerPwc?.data.orders.pagination]);

  const breadcrumbs = LABELS.BREADCRUMBS.map((item) => t(item));

  if (isLoading) {
    return (
      <PageWrapper pageTitle={t(LABELS.PAGE_TITLE)} breadcrumbs={breadcrumbs}>
        <LoadingIndicator padded />
      </PageWrapper>
    );
  }

  if (!customerPwc?.data) {
    return null;
  }

  return (
    <PageWrapper breadcrumbs={breadcrumbs} pageTitle={t(LABELS.PAGE_TITLE)}>
      <CustomerDetails
        pagination={pagination}
        onChangePage={changePage}
        onChangeRowsPerPage={changeRowsPerPage}
        customerPwcData={customerPwc?.data}
        isLoading={isLoading}
        onSort={onSort}
        sorting={sorting}
      />
    </PageWrapper>
  );
};
