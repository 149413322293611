import styled from '@emotion/styled';

import { BarSwitch } from 'components/inputs/switch/barSwitch';

export const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 25,
  padding: '0 10px',
});

export const Disclaimer = styled.div(({ theme }) => ({
  fontSize: 12,
  color: theme.colors.inactive,
  display: 'flex',
  alignItems: 'center',
  marginTop: -15,
  gap: 4,
  '& path': {
    fill: theme.colors.inactive,
  },
}));

export const StyledBarSwitch = styled(BarSwitch)(() => ({
  width: 250,
}));
