import { t } from 'i18next';
import { MerchantCheckoutSettings, PwcThemeMode } from 'services/merchants/types';

import { FormFieldType } from 'components/form/formLayout/types';

import RoutePath from 'router/path';

import { MerchantSettingsFormField } from './types';

export const KEYS = {
  ROUTE_MATCH: `${RoutePath.Pwc.Accounts.ById}/:route`,
  MAX_LOGO_SIZE: 1024 * 1024,
  UUID: 'uuid',
  TITLE_ENABLED: 'titleEnabled',
};

export const LABELS = {
  PREVIEW_DISCLAIMER: ['page.merchantSettings.landing.preview.disclaimer', 'Save changes to update the checkout page preview.'],
  PANEL_TITLE: ['page.merchantSettings.landing.title', 'Checkout Page'],
  SAVE_BUTTON_LABEL: ['page.merchantSettings.widget.save', 'Save'],
  CANCEL_BUTTON_LABEL: ['page.merchantSettings.landing.cancel', 'Cancel'],
  PREVIEW_SECTION_TITLE: ['page.merchantSettings.landing.preview.title', 'Checkout Page Preview'],
  THEME_MODES: {
    [PwcThemeMode.Light]: ['page.merchantSettings.landing.themeMode.light', 'Light'],
    [PwcThemeMode.Dark]: ['page.merchantSettings.landing.themeMode.dark', 'Dark'],
  },
  FORM_FIELDS: {
    TITLE_ENABLED: ['page.merchantSettings.landing.form.redirectDomain.label', 'Custom Page Title'],
    TITLE: ['page.merchantSettings.landing.form.redirectDomain.label', 'Page Title'],
    LIGHT_MODE_URL: ['page.merchantSettings.landing.form.landingLogo.label', 'Logo in Light Mode Checkout Page'],
    DARK_MODE_URL: ['page.merchantSettings.landing.form.landingLogoDark.label', 'Logo in Dark Mode Checkout Page'],
    ERRORS: {
      MAX_FILE_SIZE: ['page.merchantSettings.landing.form.landingLogo.maxFileSizeError', 'Maximum file size is 1MB'],
    },
    MESSAGES: {
      LOGO: {
        SUPPORTED_FORMAT: ['page.merchantSettings.landing.form.messages.landingLogo.supportedFormat', 'Supported Formats: JPG, PNG'],
        MAX_FILE_SIZE: ['page.merchantSettings.landing.form.messages.landingLogo.maxFileSize', 'Maximum Size: 1MB'],
        SAVE_TO_UPDATE: [
          'page.merchantSettings.landing.form.messages.landingLogo.saveToUpdate',
          'Image will be updated in preview only after save',
        ],
      },
    },
  },
};

export const logoAcceptRules = {
  'image/jpg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
};

export const FORM_FIELDS: Record<string, keyof MerchantCheckoutSettings> = {
  TITLE: 'title',
  LIGHT_MODE_URL: 'lightModeLogoUrl',
  DARK_MODE_URL: 'darkModeLogoUrl',
};

export const formFields: MerchantSettingsFormField[] = [
  {
    key: FORM_FIELDS.LIGHT_MODE_URL,
    label: t(LABELS.FORM_FIELDS.LIGHT_MODE_URL),
    type: FormFieldType.File,
  },
  {
    key: FORM_FIELDS.DARK_MODE_URL,
    label: t(LABELS.FORM_FIELDS.DARK_MODE_URL),
    type: FormFieldType.File,
  },
  {
    key: KEYS.TITLE_ENABLED,
    label: t(LABELS.FORM_FIELDS.TITLE_ENABLED),
    type: FormFieldType.Custom,
  },
  {
    key: FORM_FIELDS.TITLE,
    label: t(LABELS.FORM_FIELDS.TITLE),
    type: FormFieldType.Text,
  },
];
