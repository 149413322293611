import React, { MouseEvent } from 'react';
import { PwcCustomerModel } from 'services/pwcCustomers/types';

import { TablePagination } from 'types/pagination';
import { TableSorting } from 'types/sort';

import { CustomerSection } from './sections/customer/customer';
import { OrdersSection } from './sections/orders/orders';
import { TopAreaSection } from './sections/topArea/topArea';
import { Container, SubContainer } from './styles';

export interface CustomerDetailsProps {
  customerPwcData: PwcCustomerModel;
  isLoading: boolean;
  onSort: (event: MouseEvent, property: string) => void;
  sorting: TableSorting;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (itemsPerPage: number) => void;
  pagination: TablePagination;
}

export const CustomerDetails = ({
  customerPwcData,
  isLoading,
  onSort,
  sorting,
  onChangePage,
  onChangeRowsPerPage,
  pagination,
}: CustomerDetailsProps) => {
  const sectionsProps = {
    customerData: customerPwcData,
  };

  return (
    <>
      <TopAreaSection {...sectionsProps} />
      <Container>
        <SubContainer>
          <CustomerSection {...sectionsProps} />
        </SubContainer>
        <SubContainer />
      </Container>
      <OrdersSection
        pagination={pagination}
        isLoading={isLoading}
        onSort={onSort}
        sorting={sorting}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        {...sectionsProps}
      />
    </>
  );
};
