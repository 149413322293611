import { PageWrapper } from 'components';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import { useNotifications } from 'providers/notifications/useNotifications';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCSVRecords, useGetPwcOrdersListQuery } from 'services/PwcOrders';
import { PwcOrdersListQuery } from 'services/PwcOrders/types';
import { useGetAssetListQuery } from 'services/assets';
import { useGetMerchantsListQuery } from 'services/merchants';

import { DataTableRow } from 'components/dataTable';
import { CurrencyItem } from 'components/inputs/select/currencySelect/types';

import RoutePath from 'router/path';

import selectAccount from 'state/selectors/accounts/accountSelector';
import { selectUserLevel } from 'state/slices/userSlice';

import { Asset, AssetType } from 'types/assets';
import { NotificationSeverity } from 'types/notifications';
import { SortOrder } from 'types/sort';
import { UserAccessLevel } from 'types/user';

import downloadFile from 'utils/downloadFile';
import { usePersistentFilters } from 'utils/filters';
import formatNumber from 'utils/numbers';
import { decoratePagination, getDefaultPagination, undecoratePagination } from 'utils/pagination';
import { capitalizeFirstLetter } from 'utils/string';
import { usePagination } from 'utils/tables/pagination';
import { usePersistentSearch } from 'utils/tables/search';
import { getRequestSorting, useTableSorting } from 'utils/tables/sorting';
import { useDebouncedQuery } from 'utils/tables/useDebouncedQuery';

import { hasUserS4cRole } from '../view/utils';
import { COLUMN_IDS, KEYS, LABELS } from './keys';
import { OrderList } from './orderList';
import { Filters, OrderDataRow } from './types';
import { makeFilters, prepareFilters } from './utils';

export const PwcOrderListContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pushToast } = useNotifications();
  const [isExporting, setExporting] = useState<boolean>(false);
  const { search, setSearch } = usePersistentSearch(KEYS.SEARCH_DEBOUNCE_TIMEOUT, KEYS.FILTER_KEY);
  const { filters, updateFilters, clearFilters } = usePersistentFilters<Filters>({}, KEYS.FILTER_KEY);
  const { account } = useSelector(selectAccount);
  const userLevel = useSelector(selectUserLevel);
  const isS4cUser = hasUserS4cRole(userLevel);
  const { sorting, onSort } = useTableSorting({
    defaultSorting: {
      field: COLUMN_IDS.DATE,
      direction: SortOrder.desc,
    },
  });

  const merchantListPagination = { itemsPerPage: 999, page: 1 };
  const { pagination, changeRowsPerPage, changePage, setPagination } = usePagination(getDefaultPagination(), [filters, search, sorting]);
  const pwcOrdersListQuery: PwcOrdersListQuery = useDebouncedQuery(() => {
    const baseQuery: PwcOrdersListQuery = {
      pagination: undecoratePagination(pagination),
      sort: {
        field: sorting.orderBy,
        direction: sorting.order,
      },
      search: search || undefined,
    };

    if (!isEmpty(filters)) {
      baseQuery.filters = prepareFilters(filters);
    }

    if (userLevel === UserAccessLevel.Partner) {
      return {
        ...baseQuery,
        filters: {
          ...(baseQuery.filters || {}),
          merchantUuids: [account.uuid],
        },
      };
    }

    return baseQuery;
  }, [userLevel, account, pagination, sorting, search, filters]);
  const { data, isFetching } = useGetPwcOrdersListQuery(pwcOrdersListQuery);
  const { data: merchantsList, isFetching: isMerchantsListFetching } = useGetMerchantsListQuery(
    {
      pagination: merchantListPagination,
      sort: getRequestSorting(sorting),
    },
    { skip: !isS4cUser },
  );

  const { data: assets = [] } = useGetAssetListQuery();
  const breadcrumbs = LABELS.BREADCRUMBS.map((item) => t(item));

  useEffect(() => {
    if (data?.pagination) {
      setPagination(decoratePagination(data?.pagination));
    }
  }, [data?.pagination]);

  const orders: OrderDataRow[] = (data?.orders || []).map((order) => {
    const fiatAsset = assets.find((asset) => asset.symbol === order.fiatCurrencyCode);
    const fiatAmount = order.fiatAmount ? formatNumber(Number(order.fiatAmount), fiatAsset?.displayDecimals) : order.fiatAmount;
    const cryptoAsset = assets.find((asset) => asset.symbol === order.cryptoCurrencyCode);
    const cryptoAmount = order.cryptoAmount ? formatNumber(Number(order.cryptoAmount), cryptoAsset?.displayDecimals) : order.cryptoAmount;
    return {
      ...order,
      id: order.uuid,
      fiatAmount,
      cryptoAmount: cryptoAmount ?? KEYS.CRYPTO_AMOUNT_PLACEHOLDER,
    };
  });

  const getCryptoLabel = (asset: Asset) => {
    const [symbol] = asset.symbol.split(KEYS.CRYPTO_SYMBOL_SEPARATOR);
    const chainName = capitalizeFirstLetter(asset.chainCode.replaceAll(KEYS.CRYPTO_NETWORK_SPACING_SYMBOLS_EXP, ' '));
    return `${symbol} - ${chainName}`;
  };

  const exportCSV = async () => {
    setExporting(true);
    try {
      const data = await getCSVRecords({ filters: prepareFilters(filters) });

      if (data) {
        const objectUrl = window.URL.createObjectURL(data);
        downloadFile({ name: t(LABELS.CSV_FILE_NAME, { date: dayjs().format(KEYS.DATE_FORMAT) }), source: objectUrl });
      }
    } catch (e: any) {
      pushToast({
        severity: NotificationSeverity.error,
        message: t(LABELS.CSV_DOWNLOAD_ERROR),
      });
    }

    setExporting(false);
  };

  const cryptoOptions: CurrencyItem[] = assets
    .filter((asset) => asset.type === AssetType.Crypto)
    .map((asset) => ({
      key: asset.symbol,
      icon: asset.iconUrl,
      name: asset.name,
      label: getCryptoLabel(asset),
      code: asset.symbol,
    }));

  const fiatOptions: CurrencyItem[] = assets
    .filter((asset) => asset.type === AssetType.Fiat)
    .map((asset) => ({
      key: asset.symbol,
      icon: asset.iconUrl,
      name: asset.name,
      label: asset.symbol,
      code: asset.symbol,
    }));

  const emails = [
    ...new Map(data?.orders.map((order) => [order.customerEmail, { key: order.customerEmail, label: order.customerEmail }])).values(),
  ];

  const merchantAccounts = (merchantsList?.data || []).map((merchant) => ({
    key: merchant.uuid,
    label: merchant.name,
  }));

  const navigateToOrder = ({ uuid }: DataTableRow) => navigate(RoutePath.pwc.orderById(uuid));
  const filterSettings = makeFilters({ cryptoOptions, fiatOptions, merchantAccounts, emails });

  return (
    <PageWrapper pageTitle={t(LABELS.PAGE_TITLE)} breadcrumbs={breadcrumbs}>
      <OrderList
        pagination={pagination}
        orders={orders}
        isLoading={isFetching || isMerchantsListFetching}
        onChangePage={changePage}
        onChangeRowsPerPage={changeRowsPerPage}
        onSort={onSort}
        sorting={sorting}
        onSearch={setSearch}
        search={search}
        onRowClick={navigateToOrder}
        exportProps={{
          onExport: exportCSV,
          isLoading: isExporting,
        }}
        filterProps={{
          filterSettings,
          filters,
          onFilterChange: updateFilters,
          onFiltersClear: clearFilters,
        }}
        isS4cUser={isS4cUser}
      />
    </PageWrapper>
  );
};
