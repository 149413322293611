import { OptionallyVisible } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MerchantKyc, MerchantKycStatuses } from 'services/merchants/types';

import { Button } from 'components/buttons';

import { LABELS } from '../keys';
import { TablePlaceholderContainer, TablePlaceholderText } from './styles';

interface SettlementTablePlaceholderProps {
  action: () => void;
  kyc: MerchantKyc;
}

export const SettlementTablePlaceholder = ({ action, kyc }: SettlementTablePlaceholderProps) => {
  const { t } = useTranslation();

  return (
    <TablePlaceholderContainer>
      <TablePlaceholderText>{t(LABELS.SETTLEMENT.PLACEHOLDER)}</TablePlaceholderText>
      <OptionallyVisible visible={kyc.kycStatus === MerchantKycStatuses.Approved}>
        <Button secondary onClick={action}>
          {t(LABELS.SETTLEMENT.ADD_METHOD)}
        </Button>
      </OptionallyVisible>
    </TablePlaceholderContainer>
  );
};
