import React from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';

import { LABELS } from '../../keys';
import { CustomerPwcSectionProps } from '../../types';
import { Item, ItemName, ItemValue, TopAreaContainer } from './styles';

export const TopAreaSection = ({ customerData }: CustomerPwcSectionProps) => {
  const { t } = useTranslation();

  return (
    <Panel>
      <TopAreaContainer>
        <Item>
          <ItemName>{t(LABELS.SECTIONS.TOP_AREA.CUSTOMER_ID)}:</ItemName>
          <ItemValue>{customerData.customer.uuid}</ItemValue>
        </Item>
      </TopAreaContainer>
    </Panel>
  );
};
