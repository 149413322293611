import { OptionallyVisible } from 'components';
import React, { MouseEvent } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';

import { Panel } from 'components/containers';
import DataTable from 'components/dataTable/dataTable';
import LoadingIndicator from 'components/loadingIndicator';

import { TablePagination } from 'types/pagination';
import { TableSorting } from 'types/sort';

import { COLUMNS, LABELS } from '../../keys';
import { CustomerPwcSectionProps } from '../../types';
import { OrderCell } from './components/cells';
import { DataTableContainer } from './styles';
import { OrderDataRow } from './types';
import { transformOrdersDataToTableFormat } from './utils';

export interface OrdersProps extends CustomerPwcSectionProps {
  isLoading: boolean;
  onSort: (event: MouseEvent, property: string) => void;
  sorting: TableSorting;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (itemsPerPage: number) => void;
  pagination: TablePagination;
}

const Orders = ({ onChangePage, onChangeRowsPerPage, isLoading, onSort, sorting, customerData, pagination }: OrdersProps) => {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <Panel label={t(LABELS.PANEL_TITLE)}>
        <OptionallyVisible visible={isLoading}>
          <LoadingIndicator padded />
        </OptionallyVisible>
      </Panel>
    );
  }

  const hasOrders = Boolean(customerData.orders.data.length);

  const ordersRows: OrderDataRow[] = transformOrdersDataToTableFormat(customerData.orders.data);

  return (
    <Panel label={t(LABELS.PANEL_TITLE)}>
      <OptionallyVisible visible={hasOrders}>
        <DataTableContainer>
          <DataTable
            pagination={pagination}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            columns={COLUMNS}
            rows={ordersRows}
            CellComponent={OrderCell}
            onSort={onSort}
            order={sorting.order}
            orderBy={sorting.orderBy}
          />
        </DataTableContainer>
      </OptionallyVisible>
    </Panel>
  );
};

export const OrdersSection = withTranslation()(Orders);
