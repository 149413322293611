/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import merchantsApi from 'services/merchants';
import { MerchantInfo, MerchantStatus } from 'services/merchants/types';
import { undecorateMerchantData } from 'services/merchants/utils';

interface MerchantState {
  merchant: MerchantInfo;
}

const SLICE_NAME = 'merchant';

const initialState: MerchantState = {
  merchant: {
    uuid: '',
    name: '',
    publicKey: '',
    createdAt: '',
    bridgeCustomerId: '',
    mainBankAccountUuid: null,
    status: MerchantStatus.Active,
    shift4MerchantId: null,
    ownerEmail: '',
    ownerFullName: '',
    processingFee: 0,
    webhookUrl: null,
    meshSubclientId: null,
    title: null,
    lightModeLogoUrl: '',
    darkModeLogoUrl: '',
    payout: {
      balance: '0',
      nextPayout: '',
    },
    kyc: {},
  },
};

export const merchantSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setMerchant: (state, action: PayloadAction<{ merchant: MerchantInfo } | undefined>) => {
      if (!action.payload) {
        state.merchant = initialState.merchant;
        return;
      }

      state.merchant = undecorateMerchantData(action.payload.merchant);
    },
    updateMerchantInfo: (state, action: PayloadAction<Partial<MerchantInfo>>) => {
      state.merchant = {
        ...state.merchant,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(merchantsApi.endpoints.getMerchant.matchFulfilled, (state, { payload }) => {
      const record = payload?.data;
      if (!record) {
        return;
      }

      state.merchant = undecorateMerchantData(record);
    });
  },
});

export const { updateMerchantInfo } = merchantSlice.actions;

export default merchantSlice.reducer;
