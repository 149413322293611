import React from 'react';

import { DataTableCellProps, TableCell } from 'components/dataTable';
import { OrderStatusBadge } from 'components/pwcOrderStatusBadge/orderStatusBadge';

export const StatusCell = ({ row, column }: DataTableCellProps) => {
  const value = row[column.id];

  return (
    <TableCell data-column={column.id}>
      <OrderStatusBadge status={value} />
    </TableCell>
  );
};
