import { OptionallyVisible } from 'components';
import React, { MouseEvent } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';

import { Panel } from 'components/containers';
import DataTable from 'components/dataTable/dataTable';
import LoadingIndicator from 'components/loadingIndicator';

import { TableSorting } from 'types/sort';

import { PayoutSectionProps } from '../../types';
import { TransactionCell } from './components/cells';
import { COLUMNS, LABELS } from './keys';
import { DataTableContainer } from './styles';
import { TransactionDataRow } from './types';
import { transformTransactionsDataToTableFormat } from './utils';

export interface TransactionsProps extends PayoutSectionProps {
  onSort: (event: MouseEvent, property: string) => void;
  sorting: TableSorting;
}

const Transactions = ({ payoutData, isLoading, onSort, sorting }: TransactionsProps) => {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <Panel label={t(LABELS.PANEL_TITLE)}>
        <OptionallyVisible visible={isLoading}>
          <LoadingIndicator padded />
        </OptionallyVisible>
      </Panel>
    );
  }

  const hasTransactions = Boolean(payoutData.transactions.length);

  const transactionsRows: TransactionDataRow[] = transformTransactionsDataToTableFormat(payoutData.currency, payoutData.transactions);

  return (
    <Panel label={t(LABELS.PANEL_TITLE)}>
      <OptionallyVisible visible={hasTransactions}>
        <DataTableContainer>
          <DataTable
            columns={COLUMNS}
            rows={transactionsRows}
            CellComponent={TransactionCell}
            onSort={onSort}
            order={sorting.order}
            orderBy={sorting.orderBy}
          />
        </DataTableContainer>
      </OptionallyVisible>
    </Panel>
  );
};

export const TransactionsSection = withTranslation()(Transactions);
