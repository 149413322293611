import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PwcThemeMode } from 'services/merchants/types';

import IconCrescent from 'components/icons/crescent';
import IconInfoCircled from 'components/icons/infoCircled';
import IconLight from 'components/icons/light';
import { BarSwitchOption } from 'components/inputs/switch/barSwitch';

import { LABELS } from '../keys';
import { Disclaimer, StyledBarSwitch, Wrapper } from './styles';

interface CheckoutPagePreviewProps {
  merchantUuid: string;
}
const CheckoutPagePreview = ({ merchantUuid }: CheckoutPagePreviewProps) => {
  const { t } = useTranslation();
  const [mode, setMode] = useState<PwcThemeMode>(PwcThemeMode.Light);
  const themeModeOptions: BarSwitchOption[] = [
    {
      value: PwcThemeMode.Light,
      icon: <IconLight />,
      label: t(LABELS.THEME_MODES[PwcThemeMode.Light]),
    },
    {
      value: PwcThemeMode.Dark,
      icon: <IconCrescent />,
      label: t(LABELS.THEME_MODES[PwcThemeMode.Dark]),
    },
  ];

  const handleModeChange = (option: BarSwitchOption) => {
    setMode(option.value as PwcThemeMode);
  };

  return (
    <Wrapper>
      <Disclaimer>
        <IconInfoCircled />
        {t(LABELS.PREVIEW_DISCLAIMER)}
      </Disclaimer>
      <StyledBarSwitch value={mode} onChange={handleModeChange} options={themeModeOptions} />
      <iframe
        src={`${window.s4cConfig.corWidgetUrl}pay/preview/${merchantUuid}?theme=${mode}`}
        width="100%"
        height={750}
        title="preview"
        frameBorder="none"
      />
    </Wrapper>
  );
};

export default CheckoutPagePreview;
