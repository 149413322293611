import { t } from 'i18next';

import { DataTableColumn } from 'components/dataTable';

import { SortOrder } from 'types/sort';

export const LABELS = {
  BREADCRUMBS: [['page.customers.breadcrumbs', 'Customers']],
  PAGE_TITLE: ['page.customers.list.title', 'Customer List'],
  SEARCH_PLACEHOLDER: ['page.customers.list.search.placeholder', 'Search by User Email or User ID or Participant Code'],
  TABLE_COLUMNS: {
    EMAIL: ['page.customers.list.table.columns.email', 'Email'],
    USER_ID: ['page.customers.list.table.columns.userId', 'User ID'],
    SIGNUP_AT: ['page.customers.list.table.columns.signupAt', 'Signup at'],
    LOGIN_AT: ['page.customers.list.table.columns.loginAt', 'Last login'],
    KYC_STATUS: ['page.customers.list.table.columns.kycStatus', 'KYC Status'],
    STATUS: ['page.customers.list.table.columns.status', 'Customer Status'],
    ACTIONS: ['page.customers.list.table.columns.actions', ''],
  },
  ACTIONS: {
    TITLE: ['page.customers.list.actions.title', 'Actions'],
    OPEN_CUSTOMER_DETAILS: ['page.customers.list.actions.openCustomerDetails', 'Open Customer Details'],
    COPY_USER_ID: ['page.customers.list.actions.copyUserId', 'Copy User ID'],
    COPY_USER_EMAIL: ['page.customers.list.actions.copyUserEmail', 'Copy User E-mail'],
  },
  FILTERS: {
    STATUS: {
      LABEL: ['page.customers.list.filter.status.label', 'Customer Status'],
      ACTIVE: ['page.customers.list.filter.status.active', 'Active'],
      BLOCKED: ['page.customers.list.filter.status.blocked', 'Blocked'],
      TERMINATED: ['page.customers.list.filter.status.terminated', 'Terminated'],
    },
    KYC_STATUS: {
      LABEL: ['page.customers.list.filter.kycStatus.label', 'KYC Status'],
      APPROVED: ['page.customers.list.filter.kycStatus.approved', 'Approved'],
      REJECTED: ['page.customers.list.filter.kycStatus.rejected', 'Rejected'],
      NULL: ['page.customers.list.filter.kycStatus.null', '-'],
      SUBMITTED: ['page.customers.list.filter.kycStatus.submitted', 'Submitted'],
      PENDING_APPROVAL: ['page.customers.list.filter.kycStatus.pendingApproval', 'Pending Approval'],
      LOCKED: ['page.customers.list.filter.kycStatus.locked', 'Locked'],
    },
    LAST_LOGIN: {
      LABEL: ['page.customers.list.filter.lastLogin.label', 'Last Login'],
    },
    COUNTRY: {
      LABEL: ['page.customers.list.filter.country.label', 'Country'],
    },
    DATE: {
      LABEL: ['page.customers.list.filter.date.label', 'Creation Date'],
    },
  },
};

export const COLUMN_IDS = {
  EMAIL: 'username',
  USER_ID: 'uuid',
  SIGNUP_AT: 'signupAt',
  LOGIN_AT: 'lastLoginAt',
  KYC_STATUS: 'kycStatus',
  STATUS: 'status',
  ACTIONS: 'actions',
};

export const COLUMNS: DataTableColumn[] = [
  { id: COLUMN_IDS.EMAIL, label: t(LABELS.TABLE_COLUMNS.EMAIL), sortable: true },
  { id: COLUMN_IDS.USER_ID, label: t(LABELS.TABLE_COLUMNS.USER_ID), sortable: true },
  { id: COLUMN_IDS.SIGNUP_AT, label: t(LABELS.TABLE_COLUMNS.SIGNUP_AT), sortable: true },
  { id: COLUMN_IDS.LOGIN_AT, label: t(LABELS.TABLE_COLUMNS.LOGIN_AT), sortable: true },
  { id: COLUMN_IDS.KYC_STATUS, label: t(LABELS.TABLE_COLUMNS.KYC_STATUS), sortable: true },
  { id: COLUMN_IDS.STATUS, label: t(LABELS.TABLE_COLUMNS.STATUS), sortable: true },
  { id: COLUMN_IDS.ACTIONS, label: t(LABELS.TABLE_COLUMNS.ACTIONS) },
];

export const KEYS = {
  FILTERS_KEY: 'customers',
  ITEMS_PER_PAGE_OPTIONS: [15, 20, 50, 100],
  SEARCH_DEBOUNCE_TIMEOUT: 1000,
  SIGN_UP_DATE_FORMAT: 'MMM DD, YYYY',
  LAST_LOGIN_DATE_FORMAT: 'MMM DD, HH:mm A',
  LAST_LOGIN_DATE_FORMAT_WITH_YEAR: 'MMM DD, YYYY, HH:mm A',
  DEFAULT_TABLE_SORTING: {
    FIELD: COLUMN_IDS.SIGNUP_AT,
    DIRECTION: SortOrder.desc,
  },
};
