import React, { FunctionComponent } from 'react';

import { TableCell } from 'components/dataTable';
import { DataTableCellProps } from 'components/dataTable/dataTable.types';

import { formatDate } from '../../utils';

export const DateCell: FunctionComponent<DataTableCellProps> = ({ row, column }) => {
  return <TableCell align="left">{formatDate(row[column.id])}</TableCell>;
};
