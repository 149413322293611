import React, { MouseEvent } from 'react';
import { PayoutPublicModel } from 'services/payouts/types';

import { TableSorting } from 'types/sort';

import { DestinationSection } from './sections/destination/destination';
import { OrderSection } from './sections/details/payoutDetails';
import { TopAreaSection } from './sections/topArea/topArea';
import { TotalSection } from './sections/total/total';
import { TransactionsSection } from './sections/transactions/transactions';
import { Container, SubContainer } from './styles';

export interface OrderDetailsProps {
  payoutData: PayoutPublicModel;
  isLoading: boolean;
  onSort: (event: MouseEvent, property: string) => void;
  sorting: TableSorting;
}

export const PayoutDetails = ({ payoutData, isLoading, onSort, sorting }: OrderDetailsProps) => {
  const sectionsProps = {
    payoutData,
    isLoading,
  };

  return (
    <>
      <TopAreaSection {...sectionsProps} />
      <Container>
        <SubContainer>
          <OrderSection {...sectionsProps} />
          <DestinationSection {...sectionsProps} />
        </SubContainer>

        <SubContainer>
          <TotalSection {...sectionsProps} />
        </SubContainer>
      </Container>
      <TransactionsSection {...sectionsProps} sorting={sorting} onSort={onSort} />
    </>
  );
};
