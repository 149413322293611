import styled from '@emotion/styled/macro';

export const Controls = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: 16,
  marginTop: -8,
  marginBottom: -8,
}));

export const PanelContent = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 50,
  '@media (max-width: 1400px)': {
    flexDirection: 'column',
  },
}));

export const FormContainer = styled.div(() => ({
  padding: 10,
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
}));

export const PreviewContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 25,
}));

export const Separator = styled.div(({ theme }) => ({
  height: 1,
  width: '100%',
  backgroundColor: theme.colors.container.primary,
  margin: '20px 0',
}));

export const SectionHeader = styled.h3((_) => ({
  margin: 'unset',
  fontSize: 13,
  fontWeight: 500,
  padding: '0 10px',
}));
